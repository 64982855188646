<template>
  <div class="inside-lite-wrapper">
    <div class="inside-lite-loader" v-if="busy && !error">
      <OrderLoader />
    </div>
    <div class="inside-lite-error" v-else-if="error">
      <LoadError :error="error" />
    </div>
    <div class="inside-lite-content" v-else>
      <Navbar
        :cafe="cafe"
        :target="target"
        :showArrowBack="showArrowBack"
        @go-back="navigateBack"
      />

      <OrderMenu
        :cafe="cafe"
        :menus="menus"
        ref="orderMenu"
        :target="target"
        :menu="currentMenu"
        :menu_index="currentMenuIndex"
        :cart="[]"
        @switchMenu="switchToMenu"
        @menuViewChange="(v) => (menu_view = v)"
        :tagged_menus="menus"
      />
    </div>
  </div>
</template>

<script>
import OrderLoader from "@/components/OrderLoader.vue";
import OrderMenu from "@/components/OrderMenu.vue";
import LoadError from "@/components/LoadError.vue";

import Navbar from "@/components/Navbar.vue";

import OrderAPI from "@/api/order";

export default {
  components: {
    OrderLoader,
    OrderMenu,
    LoadError,
    Navbar,
  },
  data() {
    return {
      busy: true,
      error: null,

      cafe: {},
      menus: [],
      target: {},

      menu_view: "categories",

      currentMenuIndex: 0,
    };
  },
  methods: {
    fetchData() {
      const code = this.$route.params.code;

      if (code === 'R2Zx1H2J') {
        this.$router.replace('/menu_preview/basilico');
        return;
      }

      OrderAPI.prepareInsideLite(this.$route.params.code)
        .then((data) => {
          window.location.href = data.new_link;
        })
        .catch((error) => {
          this.error = error;
        });
    },
    switchToMenu(index) {
      this.currentMenuIndex = index;

      this.$store.commit("menu/setMenu", this.menus[this.currentMenuIndex]);
      this.$store.commit("menu/buildDictionaries");
    },
    navigateBack() {
      this.$refs.orderMenu.onBackArrowPressed();
    },
  },
  computed: {
    currentMenu() {
      if (!this.menus.length) return null;

      return this.menus[this.currentMenuIndex];
    },
    showArrowBack() {
      return this.menu_view != "categories";
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style>
</style>